import useProgramSlug from '@drugfreesleep/utils/useProgramSlug';
import Link from 'next/link';

import { Background } from '../components/background/Background';
import { NavbarMenuCenter } from '../components/navigation/NavbarMenuCenter';
import { Logo } from './newLogo';

const Navbar = () => {
  const pSlug = useProgramSlug();
  return (
    <Background color=" bg-gradient-to-r from-stone-50 via-amber-50 to-violet-200 ">
      <div className="mx-3 py-2 md:mx-16 md:py-6">
        <NavbarMenuCenter
          logo={<Logo className="text-2xl text-blue-800" />}
          rightMenu={
            <div
              style={{ width: '200px' }}
              className="flex items-center justify-around bg-transparent text-blue-800"
            >
              <li>
                <Link href={pSlug ? `/p/${pSlug}/sign-in` : `/sign-in`}>
                  <div className="text-xl font-bold">Sign in</div>
                </Link>
              </li>
            </div>
          }
        />
      </div>
    </Background>
  );
};

export { Navbar };
