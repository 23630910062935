import type { ReactNode } from 'react';

type IFooterTwoRowsCopyrightProps = {
  siteName: string;
  children: ReactNode;
  references?: any[];
};

const FooterTwoRowsCopyright = (props: IFooterTwoRowsCopyrightProps) => (
  <div>
    {props.children}

    <div className="mt-10 flex flex-col items-center justify-between gap-4 border-t border-gray-300 pt-10 text-center sm:flex-row sm:text-left">
      <div className="text-sm">{`© ${new Date().getFullYear()} Weller Labs, Inc. All Rights Reserved.`}</div>
    </div>
    <div>
      {props.references ? (
        <div className="mt-5">
          <div>References</div>
          <div>
            {props.references.map((ref, index) => {
              return (
                <div className="flex py-1" key={index}>
                  {/* Use relative positioning and a smaller text size for the index */}
                  <div className="text-xs">{`${index + 1}`}</div>
                  {/* Ensure the reference text aligns nicely with the baseline of the index */}
                  <span className="relative -bottom-1 text-xs">{ref}</span>
                </div>
              );
            })}
          </div>
        </div>
      ) : null}
    </div>
  </div>
);

export { FooterTwoRowsCopyright };
