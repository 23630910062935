import { useProgramDisplay } from '@drugfreesleep/app/(program)/course/program.gql';
import classNames from 'classnames';
import Image from 'next/image';
import care from 'public/assets/images/WellerLabs.png';
import { IoMdMoon } from 'react-icons/io';
import { SiAudiomack } from 'react-icons/si';

type ILogoProps = {
  className?: string;
  name?: string;
};

const logos: any = {
  moon: () => <IoMdMoon />,
  care: () => <Image height={48} width={48} src={care} alt="" />,
  audio: () => <SiAudiomack />,
};

const Logo = ({ className, name }: ILogoProps) => {
  const display = useProgramDisplay();
  return (
    <div
      className={classNames('mx-5 flex items-center font-semibold', className)}
    >
      <div className="mr-2">{display.logo && logos[display.logo]()}</div>
      <div>{name || display.name}</div>
    </div>
  );
};

export { Logo };
