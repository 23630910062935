import { usePublicProgramComponent } from '@drugfreesleep/app/(program)/course/program.gql';
import { useConfig } from '@drugfreesleep/config/useConfig';

import { getApolloClient } from '../graphql/apollo';
import {
  NoteAtVersionDocument,
  useNoteQuery,
  usePublicNoteQuery,
} from '../graphql/generated-types';
import useMarkdown from './useMarkdown';

export const useRenderedNote = (id: number) => {
  const client = getApolloClient();
  const data = client.readQuery({
    query: NoteAtVersionDocument,
    variables: {
      id,
    },
  });
  const note = data?.note;
  const markdown = useMarkdown('');
  return markdown(note?.payload.md || '');
};

export const useNote = (id: number) => {
  const client = getApolloClient();
  const { data } = useNoteQuery({
    client,
    variables: {
      id,
    },
    skip: !id,
  });
  const note = data?.note;
  return note;
};

export const usePublicNote = (id: number) => {
  const client = getApolloClient();
  const { data } = usePublicNoteQuery({
    client,
    variables: {
      id,
    },
    skip: !id,
  });
  const note = data?.note;
  return note;
};

export const useComponent = ({
  programSlug = null,
  componentSlug = 'homepage',
}: IProps) => {
  const config = useConfig();

  let component = config?.components[componentSlug];
  if (programSlug !== null) {
    component = config?.programs[programSlug].components[componentSlug];
  }
  return useRenderedNote(component?.id);
};

interface IProps {
  programSlug?: string | null;
  componentSlug?: string;
}

// export const XManagedComponent = ({
//   programSlug = null,
//   componentSlug = 'homepage',
// }: IProps) => {
//   const component = useComponent({ programSlug, componentSlug });
//   return <>{component}</>;
// };

export const DyamicComponent = ({
  // eslint-disable-next-line unused-imports/no-unused-vars
  programSlug,
  componentSlug = 'homepage',
}: {
  programSlug?: string;
  componentSlug?: string;
}) => {
  const { note, error } = usePublicProgramComponent(componentSlug);
  const markdown = useMarkdown('');
  if (error) {
    return <div>Error: {error.message}</div>;
  }
  return <div>{markdown(note?.payload.md || '')}</div>;
};

export const ManagedComponent = DyamicComponent;
