import AppDirPage from '@appDir/page';
import { fetchStaticProps } from '@drugfreesleep/modules/graphql/fetchNote';
import { useRouter } from 'next/router';

const Page = () => {
  const router = useRouter();
  if (router.asPath !== '/') {
    // Handle dyanmic routes by triggering the router on the curent path
    router.replace(router.asPath);
  }

  return <AppDirPage />;
};

export async function getStaticProps() {
  return fetchStaticProps(['homepage']);
}

export default Page;
