'use client';

import { ManagedComponent } from '@drugfreesleep/modules/markdown/useRenderedNote';

import { Meta } from '../components/layout/Meta';
import { AppConfig } from '../config/AppConfig';
import { Footer } from './Footer';
import { Navbar } from './Navbar';

const Base = () => {
  return (
    <div className="text-gray-600 antialiased">
      <Meta title={AppConfig.title} description={AppConfig.description} />
      <Navbar />
      <ManagedComponent componentSlug="homepage" />
      <Footer />
    </div>
  );
};

export { Base };
